/*路由设置文件*/
const routers = [
    {
        path: '/Home',
        name: 'Home',
        meta:{index:1,title: '聊天助手'},
        component: () => import('../views/Home.vue')
    },
    {
        path: '/Add',
        name: 'Add',
        meta:{index:1,title: '聊天助手'},
        component: () => import('../views/Add.vue')
    },
    {
        path: '/Edit',
        name: 'Edit',
        meta:{index:1,title: '聊天助手'},
        component: () => import('../views/Edit.vue')
    },
    {
        path: '/Source',
        name: 'Source',
        meta:{index:1,title: '聊天助手'},
        component: () => import('../views/Source.vue')
    },
    {
        path: '/Answer',
        name: 'Answer',
        meta:{index:1,title: '聊天助手'},
        component: () => import('../views/Answer.vue')
    },
    {
    path: '/',
        component: () => import('../views/Home.vue')
    },
]
export default routers
