import Vue from 'vue'
import VueRouter from 'vue-router'
import routers from './router/router'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import VueVirtualScroller from "vue-virtual-scroller";
Vue.use(VueVirtualScroller);
/*引入 vant 组件开始*/
import { NavBar } from 'vant';
Vue.use(NavBar);

import { Icon } from 'vant';
Vue.use(Icon);

import { Search } from 'vant';
Vue.use(Search);

import { List } from 'vant';
Vue.use(List);

import { Cell, CellGroup } from 'vant';
Vue.use(Cell);
Vue.use(CellGroup);

import { Field } from 'vant';
Vue.use(Field);

import { ActionSheet } from 'vant';
Vue.use(ActionSheet);

import { RadioGroup, Radio } from 'vant';
Vue.use(Radio);
Vue.use(RadioGroup);

import { Divider } from 'vant';
Vue.use(Divider);

import { Stepper } from 'vant';
Vue.use(Stepper);

import { Badge } from 'vant';
Vue.use(Badge);

import { DatetimePicker } from 'vant';
Vue.use(DatetimePicker);

import { Toast } from 'vant';
Vue.use(Toast);

import { Popup } from 'vant';
Vue.use(Popup);

import { Dialog } from 'vant';
Vue.use(Dialog);

import { Switch } from 'vant';
Vue.use(Switch);

import { Image} from 'vant';
Vue.use(Image);

import { Picker } from 'vant';
Vue.use(Picker);

import { Overlay } from 'vant';
Vue.use(Overlay);
/*引入 vant 组件结束*/

Vue.use(VueRouter)
Vue.config.productionTip = false
const router = new VueRouter({
  mode: 'history',
  base: "/",//部署的子路径
  routes: routers
})
new Vue({
  el: '#app',
  router,
  render: h => h(App),
}).$mount('#app')
